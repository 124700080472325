import React, { useState, useEffect } from "react"
import cx from "classnames"
import Reveal from "react-reveal/Reveal"

import Wrapper from "../layout/wrapper/wrapper"
import FormattedLink from "../formattedLink/formattedLink"

import Insights from "../../../assets/svg/icon-insights.svg"
import Blog from "../../../assets/svg/icon-blog.svg"
import Webinar from "../../../assets/svg/icon-webinar.svg"

import styles from "./furtherReading.module.scss"

const FurtherReading = ({ blog, insight, webinar, background }) => {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns([
      { name: "Blog", ...blog, catIcon: () => <Blog /> },
      { name: "Webinar", ...webinar, catIcon: () => <Webinar /> },
      { name: "Insight", ...insight, catIcon: () => <Insights /> },
    ])
  }, [blog, insight, webinar])

  return (
    <Wrapper background={background}>
      <h2 className={styles.sectionTitle}>Further Reading</h2>
      <Reveal effect="fadeInDown">
        <div className={cx("grid fullgap", styles.columnsWrapper)}>
          {columns?.map(column => (
            <div key={column.name} className={cx("small-12 medium-6 large-4", styles.column)}>
              <div className={cx(styles.header, styles[column.name.toLowerCase()])}>
                <p className={styles.category}>
                  {column.catIcon()}
                  {column.name}
                </p>
                <p className={styles.title}>{column.title}</p>
                <img className={styles.icon} src={column.icon.asset.url} alt={column.icon.alt} />
              </div>
              <div className={styles.textWrapper}>
                <p className={styles.text}>{column.text}</p>
                <div>
                  <FormattedLink type="link-pink" link={column.url}>
                    <span className="visually-hidden">{column.title} </span>
                    {column.linkLabel}
                  </FormattedLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Reveal>
    </Wrapper>
  )
}

export default FurtherReading
