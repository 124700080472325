import React from "react"
import cx from "classnames"
import Img from "gatsby-image"
import Reveal from "react-reveal/Reveal"

import FormattedLink from "../formattedLink/formattedLink"
import Wrapper from "../layout/wrapper/wrapper"

import styles from "./columns.module.scss"
import "./columns.module.scss"

// Used for data for 3 columns and simple columns

const Columns = ({ columns, background, alignment, pink, sectionTitle, slug }) => (
  <Wrapper background={background}>
    {sectionTitle ? (
      <h2 className={styles.sectionTitle} id={slug?.current}>
        {sectionTitle}
      </h2>
    ) : null}
    <Reveal effect="fadeInDown">
      <div className="grid fullgap">
        {columns.map((column, id) => (
          <div
            key={`${column.title}-${id}`}
            className={cx(
              "gridItem small-12 medium-4",
              styles.item,
              alignment === "center" ? styles.center : "",
              pink ? styles.pink : ""
            )}
          >
            {/* If SVG is used please use URL to aviod blurr  /todo replace with ready component */}
            {/* todo: refactor */}
            {column.icon?.asset?.url ? (
              <img className={styles.image} src={column.icon.asset.url} alt={column.icon.alt} />
            ) : (
              <Img
                className={styles.image}
                fluid={column.icon?.asset.fluid}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            )}
            <h2 className={styles.title}>{column.title}</h2>
            <p>{column.text}</p>
            <p>
              {column.url ? (
                <FormattedLink type="link-pink" link={column.url}>
                  {column.linkLabel}
                </FormattedLink>
              ) : null}
            </p>
          </div>
        ))}
      </div>
    </Reveal>
  </Wrapper>
)

export default Columns
