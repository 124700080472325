import React from "react"
import Img from "gatsby-image"
import Reveal from "react-reveal/Reveal"
import cx from "classnames"

import FormattedLink from "../formattedLink/formattedLink"

import styles from "./customQuote.module.scss"

const CustomQuote = ({ linkLabel, url, logo, bgImage, quoteText, quoteTextLarge, isQuote }) => {
  const isImage = !!bgImage?.asset?.fluid

  return (
    <Reveal effect="fadeInDown">
      <section className={styles.wrapper}>
        {bgImage?.asset?.fluid ? (
          <Img
            className={styles.bgImage}
            fluid={bgImage?.asset?.fluid}
            alt=""
            imgStyle={{
              objectFit: "contain",
            }}
          />
        ) : null}

        <div className={styles.inner}>
          <div className="grid fullgap">
            <div className="small-12 large-7">
              <Reveal effect="fadeInDown">
                <div className={cx(styles.textWrapper, isQuote ? styles.quote : "", isImage ? " " : styles.noImage)}>
                  <p>
                    <span className={styles.markStart}>“</span>
                    {quoteText}
                    {quoteTextLarge ? null : <span className={styles.markEnd}>”</span>}
                  </p>
                  {quoteTextLarge ? (
                    <p className={styles.largeText}>
                      <span>
                        {quoteTextLarge} <span className={styles.markEndBig}>”</span>
                      </span>
                    </p>
                  ) : null}
                  {url && <FormattedLink type="link-pink" link={url} label={linkLabel} />}
                </div>
              </Reveal>
            </div>
            <div className="small-12 large-4 large-offset-8">
              <div className={styles.logoWrapper}>
                <img className={styles.logo} src={logo.asset.url} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Reveal>
  )
}

export default CustomQuote
