import React from "react"

import Clients from "../clients/clients"
import Columns from "../columns/columns"
import Quote from "../quote/quote"
import Form from "../form/form"
import Cta from "../cta/cta"
import TextWithOptImgPB from "../textWithOptImgPB/textWithOptImgPB"
import VideoEmbed from "../videoEmbed/videoEmbed"
import Accordion from "../accordion/accordion"
import FurtherReading from "../furtherReading/furtherReading"
import CustomQuote from "../customQuote/customQuote"
import TextForm from "../textForm/textForm"

const PageBuilder = ({ pageBuilder: { pageBuilderContents } }) => {
  const getComponents = content => {
    switch (content?._type) {
      case "clientsPB":
        return <Clients key={content._key} {...content} />
      case "columns":
        return <Columns key={content._key} {...content} />
      case "quotePB":
        return <Quote key={content._key} {...content} {...content.quote.tabs.content} />
      case "formPB":
        return <Form key={content._key} {...content} {...content.form} />
      case "ctaPB":
        return <Cta key={content._key} {...content} {...content.cta} />
      case "textWithOptImgPB":
        return <TextWithOptImgPB key={content._key} {...content} />
      case "videoEmbed":
        return <VideoEmbed key={content._key} {...content} />
      case "accordion":
        return <Accordion key={content._key} {...content} />
      case "furtherReading":
        return <FurtherReading key={content._key} {...content} />
      case "customQuote":
        return <CustomQuote key={content._key} {...content} />
      case "textForm":
        return <TextForm key={content._key} {...content} />
      default:
        return null
    }
  }
  return <>{pageBuilderContents.map(content => getComponents(content))}</>
}

export default PageBuilder
