import React, { useState, useRef } from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import BlockContent from "../blockContent/blockContent"

import styles from "./accordionSlide.module.scss"

const AccordionSlide = ({ title, text, id }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [setHeight, setHeightState] = useState("0px")
  const content = useRef(null)

  const ToggleAccordion = () => {
    setIsOpen(!isOpen)
    setHeightState(isOpen ? "0px" : `${content.current.scrollHeight}px`)
  }

  return (
    <div className="gridItem small-12 medium-offset-1 medium-10 ">
      <div className={cx(styles.item, isOpen ? styles.isOpen : "")}>
        <button className={styles.title} onClick={ToggleAccordion} aria-controls={`sect-${id}`} id={`accordion-${id}`}>
          {title}
        </button>
        <div
          className={styles.text}
          ref={content}
          style={{ height: `${setHeight}` }}
          aria-hidden={!isOpen}
          id={`sect-${id}`}
          role="region"
          aria-labelledby={`accordion-${id}`}
        >
          <BlockContent blocks={text} />
        </div>
      </div>
    </div>
  )
}

export default AccordionSlide

AccordionSlide.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
}
