import React from "react"

import BlockContent from "../blockContent/blockContent"
import Form from "../form/form"

import styles from "./textForm.module.scss"

const TextForm = ({ text, form }) => (
  <div className={styles.wrapper}>
    <div className="grid">
      <div className="gridItem small-12 medium-6">
        <BlockContent blocks={text} />
      </div>
      <div className="gridItem small-12 medium-6">
        <Form {...form} noPadding={true} />
      </div>
    </div>
  </div>
)

export default TextForm
