import React from "react"
import cx from "classnames"
import { useInView } from "react-intersection-observer"

import BlockContent from "../blockContent/blockContent"
import InlineImage from "../blockContent/inlineImage/inlineImage"
import Wrapper from "../layout/wrapper/wrapper"
import VideoInline from "../videoInline/videoInline"

import styles from "./textWithOptImgPB.module.scss"

const TextWithOptImgPB = ({ text, background, image, imgPosition, width, videoFile, removeSpaces }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  return (
    <Wrapper background={background} noPadding={removeSpaces && true}>
      <div className={cx(styles.inner, width === "full" ? styles.fullWidth : "", styles[imgPosition])} ref={ref}>
        {/* VIDEO */}
        {videoFile && inView ? (
          <div className={cx(styles.imgWrapper, removeSpaces && styles.reducedSpacing)}>
            <VideoInline {...videoFile.asset} poster={image?._rawAsset} visible={inView} />
          </div>
        ) : null}

        {/* IMAGE */}
        {!videoFile && image?._rawAsset ? (
          <div className={styles.imgWrapper}>
            <InlineImage image={image?._rawAsset} alt={image?.alt} />
          </div>
        ) : null}

        {/* TEXT */}
        {text ? (
          <div className={styles.text}>
            <BlockContent blocks={text} />
          </div>
        ) : null}
      </div>
    </Wrapper>
  )
}

export default TextWithOptImgPB
