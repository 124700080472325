import React from "react"
import PropTypes from "prop-types"

import AccordionSlide from "./accordionSlide"
import Wrapper from "../layout/wrapper/wrapper"
import BlockContent from "../blockContent/blockContent"

import styles from "./accordion.module.scss"

const Accordion = ({ title, sections, text }) => (
  <Wrapper>
    <h2 className={styles.title}>{title}</h2>
    <div className="grid">
      {sections.map(item => (
        <AccordionSlide key={item._key} id={item._key} title={item.title} text={item._rawText} />
      ))}
    </div>
    {text?.length ? (
      <div className={styles.additionalText}>
        <BlockContent blocks={text} />
      </div>
    ) : null}
  </Wrapper>
)

export default Accordion

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
}
